<script setup lang="ts">
const { stopImpersonating, isStoppingImpersonation } = useImpersonation();
</script>

<template>
  <div
    class="border-b border-orange-900 bg-orange-500 p-2 text-center text-sm font-medium text-orange-900"
  >
    You are in impersonation mode. Please proceed with caution.
    <button class="underline" @click="stopImpersonating">
      {{ isStoppingImpersonation ? 'Stopping...' : 'Stop Impersonating' }}
    </button>
  </div>
</template>
